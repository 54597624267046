<template>
  <transition name="fade">
    <div
      class="modal-transition-container"
      v-if="active && embed_url && height > 0 && width > 0"
      @click="$emit('close')"
    >
      <div class="modal">

        <!-- Close Button. -->
        <svg class="close-button" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path class="themed-close-cross" fill-rule="evenodd" clip-rule="evenodd" d="M15.9998 29.3334C23.3636 29.3334 29.3332 23.3639 29.3332 16.0001C29.3332 8.63628 23.3636 2.66675 15.9998 2.66675C8.63604 2.66675 2.6665 8.63628 2.6665 16.0001C2.6665 23.3639 8.63604 29.3334 15.9998 29.3334Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path class="themed-close-cross" d="M20 12L12 20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path class="themed-close-cross" d="M12 12L20 20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
        </svg>

        <!-- Container. -->
        <div class="container">
          <!-- <CloseButton class="close-button" @click="$emit('close')" /> -->
          <iframe
            class="video"
            v-if="embed_url && height > 0 && width > 0"
            :src="embed_url"
            frameborder="0"
            allowfullscreen
            v-aspect-ratio="height / width"
          />
        <div/>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import CloseButton from './CloseButton'

export default {
  props: {
    active: Boolean,
    embed_url: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    }
  },
  watch: {
    'active': function (newVal, prevVal) {
      document.body.style.overflowY = (newVal) ? 'hidden' : 'auto'
      document.documentElement.style.overflowY = (newVal) ? 'hidden' : 'auto'
      // console.log(this.height, this.width, this.height / this.width)
    }
  },
  components: {
    // CloseButton
  }
}
</script>

<style lang="scss" scoped>
@import "./../styles/theme";
@import "./../styles/variables";

@include themed-close-cross;
.modal-transition-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30;
    margin-right: -4px;
    margin-top: -4px;
    .close-button {
      position: absolute;
      top: 48px;
      right: 4.16667%;
      cursor: pointer;
      @media (max-width: 800px) {
        top: 24px;
        right: 26px;
      }
      @media (max-width: 900px) and (max-height: 500px)  {
        display: none;
      }
    }
  }
}

.video {
  max-width: 1440px !important;
  // max-width: 2000px !important;
  width: 100% !important;
  background-color: transparent; //red;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 80px;
  padding-top: 152px;
  padding-bottom: 152px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    padding: 32px;
    padding-top: 24px;
  }
  @media (max-width: 900px) and (max-height: 500px)  {
    padding: 32px;
    padding-top: 24px;
  }
}

@include theme('light') {
  .modal {
    @include theme-transition;
    background-color: rgba(#fff, .9);
    color: black;
  }
  .video {
    @include theme-transition;
    // background-color: $light-placeholder;
  }
}

@include theme('dark') {
  .modal {
    @include theme-transition;
    background-color: rgba(#000, .9);
    color: white;
  }
  .video {
    @include theme-transition;
    // background-color: $dark-placeholder;
  }
}

$transition-time: 0.3s;

.fade-enter-active, .fade-leave-active {
  transition: opacity $transition-time ease-in-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
