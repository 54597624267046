<template>
  <div
    :class="{
      'has-pointer': showButton,
      'media-item': true,
      'alt-image': true,
      'no-background': (hideBackground === true)
    }"
    v-on:click="onClick"
  >
    <video
      v-if="video"
      :src="video.url"
      :poster="imageSource"
      autoplay
      muted
      loop
      playsinline
      v-aspect-ratio="aspect"
    />
    <img
      v-else-if="image"
      :src="imageSource"
      :alt="image.alt"
      v-aspect-ratio="aspect"
    />
    <div
      v-else
      v-aspect-ratio="aspect"
    />

    <button
      v-if="showButton"
      :class="{
        'button-left': (buttonSide === 'left'),
        'button-right': (buttonSide === 'right'),
        'button-top-left': (buttonSide === 'top-left'),
        'button-center-big': (buttonSide === 'button-center-big'),
        'button-blank': (buttonSide === 'button-blank'),
        'button-center-big-images': (buttonSide === 'button-center-big-images'),
        'legacy-style': legacyStyle
      }"
    >
      <!-- Old Icon - pre 2020 -->
      <!-- <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34 18H42V26" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M26 42H18V34" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M41.9998 18L32.6665 27.3333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18 42L27.3333 32.6666" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg> -->

       <!-- Show Video - New Icon - 2020  -->
      <svg v-if="buttonSide === 'button-center-big'" width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25 17V49L47 33L25 17Z" stroke="white" stroke-width="2"/>
        <path d="M33 65C50.6731 65 65 50.6731 65 33C65 15.3269 50.6731 1 33 1C15.3269 1 1 15.3269 1 33C1 50.6731 15.3269 65 33 65Z" stroke="white" stroke-width="2"/>
        <title>Play Video</title>
      </svg>
      
      <!-- Show Images Icon -->
      <svg v-if="buttonSide === 'button-center-big-images'" width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M33 65C50.6731 65 65 50.6731 65 33C65 15.3269 50.6731 1 33 1C15.3269 1 1 15.3269 1 33C1 50.6731 15.3269 65 33 65Z" stroke="white" stroke-width="2"/>
        <rect x="39" y="38.0002" width="8.99995" height="8.99995" stroke="white" stroke-width="2"/>
        <rect x="29" y="38.0002" width="7.99995" height="8.99995" stroke="white" stroke-width="2"/>
        <rect x="18" y="38.0002" width="8.99995" height="8.99995" stroke="white" stroke-width="2"/>
        <rect x="18" y="18" width="29.9998" height="19.9999" stroke="white" stroke-width="2"/>
      </svg>


      <!-- Legacy Icon - this probably would have also supported 'legacy-style' -->
      <svg v-if="[ 'left', 'right', 'top-left',].includes(buttonSide)" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
        <path d="M26.25 22.5V37.5L36.75 30L26.25 22.5Z" stroke="white" stroke-width="2"/>
        <path d="M30 45C38.2843 45 45 38.2843 45 30C45 21.7157 38.2843 15 30 15C21.7157 15 15 21.7157 15 30C15 38.2843 21.7157 45 30 45Z" stroke="white" stroke-width="2"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="60" height="60" fill="white"/>
        </clipPath>
        </defs>
        <title>Play Video</title>
      </svg>

    </button>

    <!--
      div Keeps existing sizing so we don't break our
      existing position around this obj
      We can tunr this off for better positioning.
    -->
    <div v-if="legacyStyle === true" :style="{height: '60px'}"/>

  </div>
</template>

<script>
import ImageSizes from '../mixins/ImageSizes'

export default {
  mixins: [ImageSizes],
  props: {
    image: [Object, Boolean],
    video: [Object, Boolean],
    aspect: Number,
    showButton: {
      type: Boolean,
      default: false
    },
    useImageofSize: {
      type: String,
      default: undefined
    },
    buttonSide: {
      type: String,
      default: 'left',
      validator: function (value) {
        return [
          'button-blank', 
          'left', 'right', 'top-left', 
          'button-center-big', 
          'button-center-big-images'
        ].indexOf(value) !== -1
      }
    },
    legacyStyle: {
      type: Boolean,
      default: false
    },
    hideBackground: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imageSource: function () {
      // Make sure we handle an empty image correctly
      if (this?.image === undefined) {
        return undefined
      }

      // If the image size prop exists lets get it or in error return the main URL
      let url
      if (typeof this.useImageofSize === 'string') {
        // Make sure the key exists, is supported and is not a dimension (number)
        if (
          this?.image?.sizes[this.useImageofSize] !== undefined && 
          typeof this?.image?.sizes[this.useImageofSize] === 'string' &&
          this.$imageSizesSupported.includes(this.useImageofSize)
        ) {
          url = this?.image?.sizes[this.useImageofSize]
        } else {
          console.warn(`@MediaItem->image of size: ${this.useImageofSize} does not exist or is not supported. Using full sized image instead`)
          url = this.image.url
        }
      } else {
        // By default behave like it used to with no size support
        url = this.image.url
      }
      
      return url
    }
  },

  methods: {
    onClick () {
      if (this.showButton) {
        this.$emit('click')
      } else {
        console.warn('@click ~expected warning not bug~', 'showButton is false')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/buttons";
@import "../styles/theme";
@import "../styles/grid";
@import "px-par/index.scss";

.media-item {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  img, video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// Hover Effect
.media-item {
  &:hover {
    button {
      opacity: 1;
      visibility: visible;
    }
  }

  button {
    transition: opacity 0.3s;
    opacity: 0.8;

    @media (max-width: 750px) {
      visibility: visible;
      opacity: 1;
    }
  }
}

button {
  position: absolute;

  transform: none;
  // z-index: 2;
  width: 60px;
  height: 60px;
  // transform: translateY(-62px);
  background: transparent;
  outline: none;
  border: 0px solid white;
  border-radius: 100%;

  cursor: pointer;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .image {
    height: 100%;
  }
}

.has-pointer {
  cursor: pointer;
}

$lr-margin: 6px; // padding for button.

/* Class To Position Button At Bottom Left & Right. */
.button-right {
  bottom: $lr-margin;
  right: $lr-margin;
}

.button-left {
  bottom: $lr-margin;
  margin-right: auto;
  left: $lr-margin;
}

.button-blank {
  display: none;
}

.button-left.legacy-style {
  bottom: 66px;
}

.button-right.legacy-style {
  bottom: 66px;
}

// .button-top-left.legacy-style {}

/* Class To Position Button At Top Left. */
.button-top-left {
  top: $lr-margin;
  left: $lr-margin;
}

/* Class to position in the center - Ryan M's new play icon centered */
.button-center-big, 
.button-center-big-images {
  $buttion_width: 64;
  position: absolute;
  /* background: red; */
  width: px_par(522,  #{$buttion_width * 1});
  padding-top: px_par(522,  #{$buttion_width * 1});
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1100px) {
    width: px_par(522,  #{$buttion_width * 1.5});
    padding-top: px_par(522,  #{$buttion_width * 1.5});
  }

   @media (max-width: 700px) {
    width: px_par(522,  #{$buttion_width * 2});
    padding-top: px_par(522,  #{$buttion_width * 2});
  }

  svg {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

@include theme('light') {
  .alt-image {
    &:not(.no-background){
      background-color: $light-placeholder;
      @include theme-transition;
    }
  }
}

@include theme('dark') {
  .alt-image {
    &:not(.no-background){
      @include theme-transition;
      background-color: $dark-placeholder;
    }
  }
}
</style>
