export const IMAGE_SIZES = {
  '1536x153': '1536x153',
  '2048x2048': '2048x2048',
  'large': 'large', 
  'medium': 'medium',
  'medium_large': 'medium_large', 
  'thumbnail': 'thumbnail'
}

export default {
  beforeCreate () {
    this.$imageSizes = IMAGE_SIZES
    this.$imageSizesSupported = Object.keys(IMAGE_SIZES)
  }
}
